import axios from 'axios';
import config from '../config/config';


export const updateregister = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/register`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
};

export const get_levelcounts = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/getlevelcount`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
};

export const checkuser = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/checkuser`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const get_planAlist = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/planAlist`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const get_eligible = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/getelligibles`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const updatemissed = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/register`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}


export const get_emergencydistributedetails = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/Emergencydistribute`,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
};

export const canceldistribute = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/canceldistribute`,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
};

export const updatedistribute = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/updatedistribute`,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
};

export const get_distributedetails = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/distributedetails`,
      data: payload
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
};

export const getrecords = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/getrecords`,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
};

export const levelupgrade = async (payload) => {
  console.log('payload: ', payload);
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/levelupgrade`,
      data: payload,
    });
    console.log(resp.data, 'ssssssrespdatarespdatarespdata')
    return {
      data: resp.data,
    };
  } catch (err) {
    console.log('err: ', err);

  }
};


export const getuserplandata = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}Userapi/get-current-user-data`,
      data: payload
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const getuserplandataSingle = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}Userapi/get-current-user-data-single`,
      data: payload
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const getplandataSingle = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}Userapi/get-current-user-plan-data-single`,
      data: payload
    });
    return {
      data: resp?.data,
    };
  } catch (err) {

  }
}






export const plandata = async (payload) => {
  try {
    let resp = await axios({
      method: "get",
      url: `${config.Server_URL}Userapi/plan-data`,
    });
    return {
      data: resp?.data,
    };
  } catch (err) {

  }
}


export const submitJoinplandata = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}Userapi/get-current-user-data`,
      data: payload
    });
    return {
      data: resp?.data,
    };
  } catch (err) {

  }
}

export const getadmindetails = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}Userapi/getadmindetails`,
      data: payload
    });
    return {
      data: resp?.data,
    };
  } catch (err) {

  }
}



export const getmoreinfo = async (payload) => {
  try {
    let resp = await axios({
      method: "get",
      url: `${config.Server_URL}Userapi/getmoreinfo`,
      data: payload
    });
    return {
      data: resp?.data,
    };
  } catch (err) {

  }
}

export const gethandbookfile = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}Userapi/getupdateddoc`,
      data: payload

    });
    console.log(resp);
    return {
      data: resp?.data,
    };
  } catch (err) {

  }
}


export const getearnpercentage = async () => {
  try {
    let resp = await axios({
      method: "get",
      url: `${config.Server_URL}Userapi/get-earn-percentage`,

    });
    console.log(resp);
    return {
      data: resp?.data,
    };
  } catch (err) {

  }
}