import isEmpty from "is-empty"
import { connection } from "../helper/connection"
import config from '../config/config'
import { Multicall } from "ethereum-multicall"
import { bignumber } from "../helper/customMath"
import Web3 from "web3";
import BusdABI from '../ABI/BusdABI.json'
import DefiQABI from '../ABI/DefiQABI.json'
import { convert } from "../helper/convert"
import syncEach from 'sync-each';
import { levelupgrade, submitJoinplandata } from "./apiAction"
import { toastAlert } from "../helper/toastAlert";


export async function getAllowanceDetails(contractAddr, spender) {//busdadd,ambasscontract
    var get = await connection();
    console.log(get, "======incontractaction")
    try {
        if (get && get.web3) {
            var web3 = new Web3(get.web3);
            var owner = get.address;
            var Contract = new web3.eth.Contract(BusdABI, contractAddr);
            var result1 = await Contract.methods.allowance(
                owner,
                spender
            ).call();
            result1 = parseFloat(result1) / 10 ** 18

            var deficontract = new web3.eth.Contract(DefiQABI, config.ambasscontract);
            var joinamt = await deficontract.methods.JoinAmount().call();
            joinamt = parseFloat(joinamt) / (10 ** 18);

            return {
                value: result1,
                status: true,
                joinamt: joinamt
            };
        } else {
            return {
                value: {},
                status: false,
                joinamt: 0
            };
        }
    } catch (err) {
        console.log('errrrrrrrrrererererr: ', err);
        return {
            value: {},
            status: false,
            joinamt: 0
        };
    }
}
export async function approveAction(contractAddr, RefAddress) {
    var get = await connection();
    try {
        if (get && get.web3) {
            var web3 = new Web3(get.web3);
            var address = get.address;
            var deficontract = new web3.eth.Contract(DefiQABI, config.ambasscontract);
            var joinamt = await deficontract.methods.JoinAmount().call();
            if (isEmpty(RefAddress)) {
                return {
                    status: false,
                    message: 'Referral address should not be empty'
                }
            }
            else if ((RefAddress).toLowerCase() == (address).toLowerCase()) {
                return {
                    status: false,
                    message: "Wallet and Referral address are same"
                }
            }
            var checkaddress = web3.utils.isAddress(RefAddress)
            if (checkaddress == false) {
                return {
                    status: false,
                    message: 'Invalid Address'
                }
            }
            else {
                joinamt = parseFloat(joinamt) / (10 ** 18);
                var Contract = new web3.eth.Contract(BusdABI, contractAddr);

                var balance = await Contract.methods.balanceOf(address).call();
                balance = parseFloat(balance) / (10 ** 18);
                if (parseFloat(joinamt) > parseFloat(balance)) {
                    return {
                        value: {},
                        status: false,
                        message: 'Insufficient USDT Balance',
                        approvereturnval: 0,
                        joinamt: 0
                    };
                }
                var approveAmt = 1000000000 * (10 ** 18);
                approveAmt = await convert(approveAmt);
                var result1 = await Contract.methods.approve(
                    config.ambasscontract,
                    approveAmt.toString()
                ).send({ from: address });

                var approvereturnval = result1 && result1.events && result1.events.Approval && result1.events.Approval.returnValues && result1.events.Approval.returnValues.value ?
                    result1.events.Approval.returnValues.value : 0
                approvereturnval = parseFloat(approvereturnval) / 10 ** 18
                return {
                    value: result1,
                    status: true,
                    message: 'Approved success',
                    approvereturnval: approvereturnval,
                    joinamt: joinamt
                };
            }
        } else {
            return {
                value: {},
                status: false,
                message: 'Transaction Rejected',
                approvereturnval: 0,
                joinamt: 0
            };
        }
    } catch (err) {
        console.log('err: ', err);
        return {
            value: {},
            status: false,
            message: 'Transaction Rejected',
            approvereturnval: 0,
            joinamt: 0
        };
    }
}

//--------------------
export const JoinNowAction = async (pid, amount, useraddress, RefAddress) => {
    console.log(RefAddress, pid, amount)
    var get = await connection();
    try {
        if (get && get.web3) {
            var address = get.address
            var web3 = new Web3(get.web3);
            if (isEmpty(RefAddress)) {
                return {
                    status: false,
                    message: 'Referral address should not be empty'
                }
            }
            else {
                
                let text = ["AMBPBRONZE", "AMBPSILVER", "AMBPGOLD", "AMBPDIAMOND", "AMBPPLATINUM"]
                let outputdata = RefAddress.replace(/[0-9]/g, '');
                let output = text.includes(outputdata)
                console.log(output, outputdata, "haidata");
                if (output != true) {
                    return {
                        status: false,
                        message: 'Invalid Referral id'
                    }
                }

                var approveamt = await convert(1000 * 1e18);
                var BuyAmount = await convert(amount * 1e18);

                var BusdBlanace = new web3.eth.Contract(BusdABI, config.BUSDAddress)
                var mlmcontract = new web3.eth.Contract(DefiQABI, config.ambasscontract)

                var checkaddress = RefAddress.replace(/\D/g, "");

                console.log(checkaddress, "checkaddresscheckaddress");


                try {


                    var refId = await mlmcontract.methods.userIdtoAddress(pid, checkaddress).call();
                    console.log(refId, "refId");


                    if ((refId).toLowerCase() == (useraddress).toLowerCase()) {
                        return {
                            status: false,
                            message: "Wallet and Referral address are same"
                        }
                    }

                    if (refId == "0x0000000000000000000000000000000000000000") {
                        return {
                            status: false,
                            message: 'Invalid Referral id'
                        }
                    }
                } catch (err) {
                    return {
                        status: false,
                        message: 'Invalid Referral id'
                    }
                }

                const balace = await BusdBlanace.methods.balanceOf(useraddress).call();

                let balac = balace / 1e18
                console.log(balac, amount, balac > amount);
                if (balac < amount) {
                    return {
                        status: false,
                        message: 'User Usdt balance is Low'
                    }
                }

                console.log(BusdBlanace, "BusdBlanace");

                await BusdBlanace.methods.approve(config.ambasscontract, approveamt.toString()).send({ gasLimit: 250000, from: useraddress }).then(async (res) => {

                    await mlmcontract.methods.joinPlan(checkaddress, pid, BuyAmount.toString()).send({ from: useraddress }).then((res) => {
                        console.log(res);
                        toastAlert("success", "Joined successfully", "");
                        setTimeout(() => {
                            window.location.reload()
                        }, 1500);

                    })
                })

            }

        }
    }
    catch (err) {
        console.log(err, 'eeeeeeee')
        return {
            status: false,
            message: 'Transaction rejected'
        }
    }
}


// export const GetLevelDetailsAction = async (settreeprogress, setallSelected, setmynetwork, setdownlinefirst, setdownlinesecond, setdownlinethird,
//     setdownlinefourth, setlevel1, setlevel2, setlevel3, setSelected) => {
//     try {
//         var get = await connection()
//         if (get && get.web3 && get.address) {
//             var address = get.address;
//             var web3 = new Web3(get.web3);
//             const multicall = new Multicall({
//                 web3Instance: web3,
//             })
//             var contractCallContext = [
//                 {
//                     reference: "details",
//                     contractAddress: config.ambasscontract,
//                     abi: DefiQABI,
//                     calls: [
//                         {
//                             reference: "directReferral",
//                             methodName: "viewplanBUserReferral",
//                             methodParameters: [address]
//                         },
//                         {
//                             reference: "viewUserReferral",
//                             methodName: "viewUserReferral",
//                             methodParameters: [address]
//                         },
//                         {
//                             reference: "userprofile",
//                             methodName: "users",
//                             methodParameters: [address],
//                         },
//                     ],
//                 },
//             ];
//             const results = await multicall.call(contractCallContext)
//             var dirReferral = await getFormatMulticall(results, "directReferral", 0)
//             var downlinenode = await getFormatMulticall(results, "viewUserReferral", 0)
//             var users = await getFormatMulticall(results, "userprofile", 0);
//             var myreferral = dirReferral;
//             var isexist = users[0];
//             var userid = await bignumber(users[1]);
//             var currlevel = await bignumber(users[3])
//             let _my_ref = [];
//             // console.log(myreferral, 'myreferralmyreferral')
//             if (myreferral.length > 0) {

//                 for (let noderef in myreferral) {
//                     var _myref = [
//                         {
//                             reference: "details",
//                             contractAddress: config.ambasscontract,
//                             abi: DefiQABI,
//                             calls: [
//                                 {
//                                     reference: "userprofile",
//                                     methodName: "users",
//                                     methodParameters: [myreferral[noderef]],
//                                 },
//                                 {
//                                     reference: "viewUserReferral",
//                                     methodName: "viewUserReferral",
//                                     methodParameters: [myreferral[noderef]],
//                                 },
//                             ],
//                         },
//                     ];
//                     const _results = await multicall.call(_myref);
//                     var _userprofile = await getFormatMulticall(_results, "userprofile", 0);
//                     var _downlinenode = await getFormatMulticall(_results, "viewUserReferral", 0);
//                     let _userid = await bignumber(_userprofile[1]);
//                     let _currlevel = await bignumber(_userprofile[3]);
//                     let _downline = {
//                         "address": myreferral[noderef],
//                         "level": _currlevel,
//                         "userid": _userid,
//                         "downlinenode": _downlinenode
//                     }
//                     _my_ref.push(_downline);
//                     if (parseFloat(noderef) + 1 == myreferral.length) {
//                         setmynetwork(_my_ref);
//                     }
//                 }
//             }

//             //******downlinecalculation  1************//
//             let downline1 = [];
//             let downline2arr = [];
//             let downline3arr = [];
//             let downline4arr = [];
//             let downline5arr = [];
//             let downline2 = [];
//             let downline3 = [];
//             let downline4 = [];
//             if (downlinenode.length > 0) {
//                 for (let node in downlinenode) {
//                     var downlineCallContext = [
//                         {
//                             reference: "details",
//                             contractAddress: config.ambasscontract,
//                             abi: DefiQABI,
//                             calls: [
//                                 {
//                                     reference: "userprofile",
//                                     methodName: "users",
//                                     methodParameters: [downlinenode[node]],
//                                 },
//                                 {
//                                     reference: "viewUserReferral",
//                                     methodName: "viewUserReferral",
//                                     methodParameters: [downlinenode[node]],
//                                 },
//                             ],
//                         },
//                     ];
//                     const results1 = await multicall.call(downlineCallContext);
//                     var downlinenode1 = await getFormatMulticall(results1, "viewUserReferral", 0);

//                     var userprofile1 = await getFormatMulticall(results1, "userprofile", 0);

//                     let userid1 = await bignumber(userprofile1[1]);
//                     let currlevel1 = await bignumber(userprofile1[3]);
//                     let downline_1 = {
//                         "address": downlinenode[node],
//                         "level": currlevel1,
//                         "userid": userid1,
//                         "downlinenode": downlinenode1
//                     }
//                     downline1.push(downline_1);
//                     downline2arr = downline2arr.concat(downlinenode1);
//                     if (parseFloat(node) + 1 == downlinenode.length) {
//                         setdownlinefirst(downline1);
//                     }
//                 }
//             }
//             //*******downline2*******//
//             if (downline2arr.length > 0) {
//                 for (let node1 in downline2arr) {
//                     var downlineCallContext0 = [
//                         {
//                             reference: "details",
//                             contractAddress: config.ambasscontract,
//                             abi: DefiQABI,
//                             calls: [
//                                 {
//                                     reference: "userprofile",
//                                     methodName: "users",
//                                     methodParameters: [downline2arr[node1]],
//                                 },
//                                 {
//                                     reference: "viewUserReferral",
//                                     methodName: "viewUserReferral",
//                                     methodParameters: [downline2arr[node1]],
//                                 },
//                             ],
//                         },
//                     ];
//                     const results2 = await multicall.call(downlineCallContext0);
//                     var userprofile2 = await getFormatMulticall(results2, "userprofile", 0);
//                     var downlinenode2 = await getFormatMulticall(results2, "viewUserReferral", 0);
//                     let userid2 = await bignumber(userprofile2[1]);
//                     let currlevel2 = await bignumber(userprofile2[3]);
//                     let downline_2 = {
//                         "address": downline2arr[node1],
//                         "level": currlevel2,
//                         "userid": userid2,
//                         "downlinenode": downlinenode2
//                     }
//                     downline2.push(downline_2);
//                     downline3arr = downline3arr.concat(downlinenode2);
//                     if (parseFloat(node1) + 1 == downline2arr.length) {
//                         setdownlinesecond(downline2);
//                     }
//                 }
//             }

//             //************downline3******************************//
//             if (downline3arr.length > 0) {
//                 for (let node2 in downline3arr) {
//                     var downlineCallContext1 = [
//                         {
//                             reference: "details",
//                             contractAddress: config.ambasscontract,
//                             abi: DefiQABI,
//                             calls: [
//                                 {
//                                     reference: "userprofile",
//                                     methodName: "users",
//                                     methodParameters: [downline3arr[node2]],
//                                 },
//                                 {
//                                     reference: "viewUserReferral",
//                                     methodName: "viewUserReferral",
//                                     methodParameters: [downline3arr[node2]],
//                                 },
//                             ],
//                         },
//                     ];
//                     const results3 = await multicall.call(downlineCallContext1);
//                     var userprofile3 = await getFormatMulticall(results3, "userprofile", 0);
//                     var downlinenode3 = await getFormatMulticall(results3, "viewUserReferral", 0);
//                     let userid3 = await bignumber(userprofile3[1]);
//                     let currlevel3 = await bignumber(userprofile3[3]);
//                     let downline_3 = {
//                         "address": downline3arr[node2],
//                         "level": currlevel3,
//                         "userid": userid3,
//                         "downlinenode": downlinenode3
//                     }
//                     downline3.push(downline_3);
//                     downline4arr = downline4arr.concat(downlinenode3);
//                     if (parseFloat(node2) + 1 == downline3arr.length) {
//                         setdownlinethird(downline3);
//                     }
//                 }
//             }

//             //************downline4******************************//
//             if (downline4arr.length > 0) {
//                 for (let node3 in downline4arr) {
//                     var downlineCallContext3 = [
//                         {
//                             reference: "details",
//                             contractAddress: config.ambasscontract,
//                             abi: DefiQABI,
//                             calls: [
//                                 {
//                                     reference: "userprofile",
//                                     methodName: "users",
//                                     methodParameters: [downline4arr[node3]],
//                                 },
//                                 {
//                                     reference: "viewUserReferral",
//                                     methodName: "viewUserReferral",
//                                     methodParameters: [downline4arr[node3]],
//                                 },
//                             ],
//                         },
//                     ];
//                     const results4 = await multicall.call(downlineCallContext3);
//                     var userprofile4 = await getFormatMulticall(results4, "userprofile", 0);
//                     var downlinenode4 = await getFormatMulticall(results4, "viewUserReferral", 0);
//                     let userid4 = await bignumber(userprofile4[1]);
//                     let currlevel4 = await bignumber(userprofile4[3]);
//                     let downline_4 = {
//                         "address": downline4arr[node3],
//                         "level": currlevel4,
//                         "userid": userid4,
//                         "downlinenode": downlinenode4
//                     }
//                     downline4.push(downline_4);
//                     downline5arr = downline5arr.concat(downlinenode4);
//                     if (parseFloat(node3) + 1 == downline4arr.length) {
//                         setdownlinefourth(downline4);
//                     }
//                 }
//             }
//             //****************************2nd level tree************************//
//             // let line1arr=[];
//             // if(downline1.length>0){
//             //   for(let y in downline1){
//             //     if(downline1[y].downlinenode.length>0){
//             //       let subline=[];
//             //       for(let z in downline1[y].downlinenode){
//             //         var IndexVal = downline2.findIndex(val => val.address == downline1[y].downlinenode[z]);
//             //         let user1 = {
//             //           name : "ID "+downline2[IndexVal].userid+" ("+downline2[IndexVal].level+")"
//             //         }
//             //         subline.push(user1);
//             //         if(parseInt(z)+1==downline1[y].downlinenode.length){
//             //           let mainline = {
//             //             "name": "ID "+downline1[y].userid+" ("+downline1[y].level+")",
//             //             "children": subline
//             //           }
//             //           line1arr.push(mainline);
//             //         }
//             //       }
//             //     }else{
//             //       let mainline = {
//             //           "name": "ID "+downline1[y].userid+" ("+downline1[y].level+")",
//             //           "children": []
//             //         }
//             //         line1arr.push(mainline);
//             //     }
//             //     if(parseInt(y)+1==downline1.length){
//             //       let mainlinearr = {
//             //         name : "ID "+userid +" ("+currlevel+")",
//             //         "children":line1arr
//             //       }
//             //       setSelected(mainlinearr);
//             //       setallSelected(mainlinearr);
//             //       let my_network = downline1.concat(downline2);
//             //       //setmynetwork(my_network);
//             //     }
//             //   }
//             // }else{
//             //   let mainlinearr = {
//             //     name : "ID "+userid +" ("+currlevel+")",
//             //     "children":[]
//             //   }
//             //   setSelected(mainlinearr);
//             //   setallSelected(mainlinearr);
//             //   let my_network = downline1.concat(downline2);
//             //   setmynetwork(my_network);
//             // }

//             // //************************3rd level tree ************************************//
//             // let line1arr=[];
//             // if(downline1.length>0){
//             //   syncEach(downline1, async function (items,next) {
//             //     if(items && items.downlinenode && items.downlinenode.length>0){
//             //       let subline=[];
//             //       syncEach(items.downlinenode, async function (subitems,next1) {
//             //         var IndexVal = downline2.findIndex(val => val.address == subitems);
//             //         if(IndexVal>=0 && downline2[IndexVal].downlinenode && downline2[IndexVal].downlinenode.length>0){
//             //           let subline2 = [];
//             //           syncEach(downline2[IndexVal].downlinenode, async function (thir_items,next2) {
//             //             var IndexVal = downline3.findIndex(val => val.address == thir_items);
//             //                 let user12 = {
//             //                     name : "ID "+downline3[IndexVal].userid+" ("+downline3[IndexVal].level+")"
//             //                 }
//             //                 subline2.push(user12);
//             //                 next2();
//             //           },function( error , success){
//             //             let mainline = {
//             //                     "name": "ID "+downline2[IndexVal].userid+" ("+downline2[IndexVal].level+")",
//             //                     "children": subline2
//             //                   }
//             //                   subline.push(mainline);
//             //             next1();
//             //           })
//             //         }else{
//             //           var IndexVal = downline2.findIndex(val => val.address == subitems);
//             //               let user1 = {
//             //                 name : "ID "+downline2[IndexVal].userid+" ("+downline2[IndexVal].level+")"
//             //               }
//             //               subline.push(user1);
//             //           next1();
//             //         }
//             //       },function (errs,t_Items) {
//             //         let mainline = {
//             //               "name": "ID "+items.userid+" ("+items.level+")",
//             //               "children": subline
//             //             }
//             //             console.log("push33")
//             //             line1arr.push(mainline);
//             //         next();
//             //       })
//             //     }else{
//             //         let mainline = {
//             //               "name": "ID "+items.userid+" ("+items.level+")",
//             //               "children": []
//             //             }
//             //             line1arr.push(mainline);
//             //             next();
//             //       }
//             //     },function (err,transformedItems) {
//             //           let mainlinearr = {
//             //             name : "ID "+userid +" ("+currlevel+")",
//             //             "children":line1arr
//             //           }
//             //           setSelected(mainlinearr);
//             //           setallSelected(mainlinearr);
//             //           settreeprogress(false)
//             //           let my_network = downline1.concat(downline2);
//             //   })
//             //   }else{
//             //         let mainlinearr = {
//             //           name : "ID "+userid +" ("+currlevel+")",
//             //           "children":[]
//             //         }
//             //         setSelected(mainlinearr);
//             //         setallSelected(mainlinearr);
//             //         settreeprogress(false)
//             //         let my_network = downline1.concat(downline2);
//             //         setmynetwork(my_network);
//             //   }

//             //**************************4th level tree*********************//
//             let line1arr = [];
//             if (downline1.length > 0) {
//                 syncEach(downline1, async function (items, next) {
//                     if (items && items.downlinenode && items.downlinenode.length > 0) {
//                         let subline = [];
//                         syncEach(items.downlinenode, async function (subitems, next1) {
//                             let IndexVal = downline2.findIndex(val => val.address == subitems);
//                             if (IndexVal >= 0 && downline2[IndexVal].downlinenode && downline2[IndexVal].downlinenode.length > 0) {
//                                 let subline2 = [];
//                                 syncEach(downline2[IndexVal].downlinenode, async function (thir_items, next2) {
//                                     let IndexVal1 = downline3.findIndex(val => val.address == thir_items);
//                                     if (IndexVal1 >= 0 && downline3[IndexVal1].downlinenode && downline3[IndexVal1].downlinenode.length > 0) {
//                                         let subline3 = [];
//                                         syncEach(downline3[IndexVal1].downlinenode, async function (fourth_items, next3) {
//                                             let Index_Val = downline4.findIndex(val => val.address == fourth_items);
//                                             let user22 = {
//                                                 name: "ID " + downline4[Index_Val].userid + " (" + downline4[Index_Val].level + ")"
//                                             }
//                                             subline3.push(user22);
//                                             next3();
//                                         }, function (error, success) {
//                                             let mainline = {
//                                                 "name": "ID " + downline3[IndexVal1].userid + " (" + downline3[IndexVal1].level + ")",
//                                                 "children": subline3
//                                             }
//                                             setlevel3(mainline);
//                                             subline2.push(mainline);
//                                             next2();
//                                         })
//                                     } else {
//                                         let IndexVal = downline3.findIndex(val => val.address == thir_items);
//                                         let user1 = {
//                                             name: "ID " + downline3[IndexVal].userid + " (" + downline3[IndexVal].level + ")"
//                                         }
//                                         subline2.push(user1);
//                                         next2();
//                                     }
//                                     // let user12 = {
//                                     //     name : "ID "+downline3[IndexVal].userid+" ("+downline3[IndexVal].level+")"
//                                     // }
//                                     // subline2.push(user12);
//                                     // next2();
//                                 }, function (error, success) {
//                                     let mainline = {
//                                         "name": "ID " + downline2[IndexVal].userid + " (" + downline2[IndexVal].level + ")",
//                                         "children": subline2
//                                     }
//                                     setlevel2(mainline)
//                                     subline.push(mainline);
//                                     next1();
//                                 })
//                             } else {
//                                 var IndexValue = downline2.findIndex(val => val.address == subitems);
//                                 let user1 = {
//                                     name: "ID " + downline2[IndexValue].userid + " (" + downline2[IndexValue].level + ")"
//                                 }
//                                 subline.push(user1);
//                                 next1();
//                             }
//                         }, function (errs, t_Items) {
//                             let mainline = {
//                                 "name": "ID " + items.userid + " (" + items.level + ")",
//                                 "children": subline
//                             }
//                             setlevel1(mainline)
//                             line1arr.push(mainline);
//                             next();
//                         })
//                     } else {
//                         let mainline = {
//                             "name": "ID " + items.userid + " (" + items.level + ")",
//                             "children": []
//                         }
//                         setlevel1(mainline);
//                         setlevel2([])
//                         line1arr.push(mainline);
//                         next();
//                     }
//                 }, function (err, transformedItems) {
//                     let mainlinearr = {
//                         name: "ID " + userid + " (" + currlevel + ")",
//                         "children": line1arr
//                     }
//                     setSelected(mainlinearr);
//                     setallSelected(mainlinearr);
//                     settreeprogress(false)
//                     let my_network = downline1.concat(downline2);
//                 })
//             } else {
//                 let mainlinearr = {
//                     name: "ID " + userid + " (" + currlevel + ")",
//                     "children": []
//                 }
//                 setSelected(mainlinearr);
//                 setallSelected(mainlinearr);
//                 settreeprogress(false);
//                 let my_network = downline1.concat(downline2);
//                 setmynetwork(my_network);
//             }

//             return {
//                 status: true,
//                 isexist: isexist,
//                 UserProfileId: userid
//             }
//         }
//         else {
//             return {
//                 status: false,
//                 isexist: '',
//                 message: 'Error on server'
//             }
//         }
//     }
//     catch (err) {
//         console.log('errasassssswwwwwwasasas: ', err);
//         return {
//             status: false,
//             isexist: '',
//             message: 'Error on server'

//         }
//     }


// }
// export const GetDetailsAction = async () => {
//     try {
//         var get = await connection()
//         if (get && get.web3 && get.address) {
//             var address = get.address;
//             var web3 = new Web3(get.web3);
//             const multicall = new Multicall({
//                 web3Instance: web3,
//             })

//             var contractCallContext = [
//                 {
//                     reference: "details",
//                     contractAddress: config.ambasscontract,
//                     abi: DefiQABI,
//                     calls: [
//                         {
//                             reference: "userprofile",
//                             methodName: "users",
//                             methodParameters: [address],
//                         },
//                         {
//                             reference: "totaluser",
//                             methodName: "totalUsers",
//                             methodParameters: []

//                         },
//                         {
//                             reference: "generationIncome",
//                             methodName: "generationIncome",
//                             methodParameters: [address]

//                         },
//                         {
//                             reference: "upgradematrixIncome",
//                             methodName: "upgradematrixIncome",
//                             methodParameters: [address]

//                         },
//                         {
//                             reference: "viewUserReferral",
//                             methodName: "viewUserReferral",
//                             methodParameters: [address]
//                         },
//                         {
//                             reference: "directReferral",
//                             methodName: "viewplanBUserReferral",
//                             methodParameters: [address]
//                         },
//                         {
//                             reference: "totalearnedAmount",
//                             methodName: "totalearnedAmount",
//                             methodParameters: [address]
//                         },
//                         {
//                             reference: "missedearning2",
//                             methodName: "getmissedvalue",
//                             methodParameters: [address, 2]
//                         },
//                         {
//                             reference: "missedearning3",
//                             methodName: "getmissedvalue",
//                             methodParameters: [address, 3]
//                         },
//                         {
//                             reference: "missedearning4",
//                             methodName: "getmissedvalue",
//                             methodParameters: [address, 4]
//                         },
//                         {
//                             reference: "missedearning5",
//                             methodName: "getmissedvalue",
//                             methodParameters: [address, 5]
//                         },
//                         {
//                             reference: "missedearning6",
//                             methodName: "getmissedvalue",
//                             methodParameters: [address, 6]
//                         },
//                         {
//                             reference: "missedearning7",
//                             methodName: "getmissedvalue",
//                             methodParameters: [address, 7]
//                         },
//                         {
//                             reference: "missedearning8",
//                             methodName: "getmissedvalue",
//                             methodParameters: [address, 8]
//                         },
//                         {
//                             reference: "missedearning9",
//                             methodName: "getmissedvalue",
//                             methodParameters: [address, 9]
//                         },
//                         {
//                             reference: "missedearning10",
//                             methodName: "getmissedvalue",
//                             methodParameters: [address, 10]
//                         },
//                         {
//                             reference: "referralIncome",
//                             methodName: "referralIncome",
//                             methodParameters: [address]
//                         },
//                         {
//                             reference: "teamCount",
//                             methodName: "teamCount",
//                             methodParameters: [address]
//                         },
//                         {
//                             reference: "totalearnedToken",
//                             methodName: "totalearnedToken",
//                             methodParameters: [address]
//                         }
//                     ],
//                 },
//             ];

//             const results = await multicall.call(contractCallContext)
//             var users = await getFormatMulticall(results, "userprofile", 0);
//             var totalusers = await getFormatMulticall(results, "totaluser", 0)
//             var dirReferral = await getFormatMulticall(results, "directReferral", 0)
//             var downlinenode = await getFormatMulticall(results, "viewUserReferral", 0)
//             var totalearnedAmount = await getFormatMulticall(results, "totalearnedAmount", 0)
//             var generatIncomee = await getFormatMulticall(results, "generationIncome", 0)
//             var upgradematxIncome = await getFormatMulticall(results, "upgradematrixIncome", 0)
//             var referralIncome = await getFormatMulticall(results, "referralIncome", 0)
//             var teamCount = await getFormatMulticall(results, "teamCount", 0)
//             var totalearnedToken = await getFormatMulticall(results, "totalearnedToken", 0)


//             var missedearning2 = await getFormatMulticall(results, "missedearning2", 0);
//             var missedearning3 = await getFormatMulticall(results, "missedearning3", 0);
//             var missedearning4 = await getFormatMulticall(results, "missedearning4", 0);
//             var missedearning5 = await getFormatMulticall(results, "missedearning5", 0);
//             var missedearning6 = await getFormatMulticall(results, "missedearning6", 0);
//             var missedearning7 = await getFormatMulticall(results, "missedearning7", 0);
//             var missedearning8 = await getFormatMulticall(results, "missedearning8", 0);
//             var missedearning9 = await getFormatMulticall(results, "missedearning9", 0);
//             var missedearning10 = await getFormatMulticall(results, "missedearning10", 0);

//             var directreferralIncome = await bignumber(referralIncome[0]);
//             var earnedToken = await bignumber(totalearnedToken[0]);
//             let myteamCount = await bignumber(teamCount[0]);

//             let l2_missedearnings = await bignumber(missedearning2[0]);
//             l2_missedearnings = parseFloat(l2_missedearnings) / 10 ** 18;
//             let l3_missedearnings = await bignumber(missedearning3[0]);
//             l3_missedearnings = parseFloat(l3_missedearnings) / 10 ** 18;
//             let l4_missedearnings = await bignumber(missedearning4[0]);
//             l4_missedearnings = parseFloat(l4_missedearnings) / 10 ** 18;
//             let l5_missedearnings = await bignumber(missedearning5[0]);
//             l5_missedearnings = parseFloat(l5_missedearnings) / 10 ** 18;
//             let l6_missedearnings = await bignumber(missedearning6[0]);
//             l6_missedearnings = parseFloat(l6_missedearnings) / 10 ** 18
//             let l7_missedearnings = await bignumber(missedearning7[0]);
//             l7_missedearnings = parseFloat(l7_missedearnings) / 10 ** 18;
//             let l8_missedearnings = await bignumber(missedearning8[0]);
//             l8_missedearnings = parseFloat(l8_missedearnings) / 10 ** 18;
//             let l9_missedearnings = await bignumber(missedearning9[0]);
//             l9_missedearnings = parseFloat(l9_missedearnings) / 10 ** 18;
//             let l10_missedearnings = await bignumber(missedearning10[0]);
//             l10_missedearnings = parseFloat(l10_missedearnings) / 10 ** 18;


//             var totalearnedAmt = await bignumber(totalearnedAmount[0]);
//             totalearnedAmt = parseFloat(totalearnedAmt) / 10 ** 18;
//             var upgradematrixIncome = await bignumber(upgradematxIncome[0]);
//             upgradematrixIncome = parseFloat(upgradematrixIncome) / 10 ** 18;
//             var genertionIncome = await bignumber(generatIncomee[0]);
//             genertionIncome = parseFloat(genertionIncome) / 10 ** 18;

//             var isexist = users[0];
//             var userid = await bignumber(users[1]);
//             var currlevel = await bignumber(users[3])

//             var RoyaltyIncome = await bignumber(users[6]);
//             RoyaltyIncome = parseFloat(RoyaltyIncome) / 10 ** 18;
//             var yourearnedAmt = await bignumber(users[4]);
//             yourearnedAmt = parseFloat(yourearnedAmt) / 10 ** 18;
//             var locktokenAmt = await bignumber(users[5]);
//             locktokenAmt = parseFloat(locktokenAmt) / 10 ** 18;
//             var upgradePending = await bignumber(users[10]);
//             upgradePending = parseFloat(upgradePending) / 10 ** 18;

//             var globalroyality1income = await bignumber(users[7]);
//             globalroyality1income = parseFloat(globalroyality1income) / 10 ** 18;
//             var globalroyality2income = await bignumber(users[8]);
//             globalroyality2income = parseFloat(globalroyality2income) / 10 ** 18;

//             // var myreferral = dirReferral && dirReferral.length
//             var myreferral = dirReferral
//             var upgradeAmount = await bignumber(users[9]);
//             upgradeAmount = parseFloat(upgradeAmount) / 10 ** 18;

//             var totaluser = await bignumber(totalusers[0]);

//             var contractCallContextTwo = [
//                 {
//                     reference: "details",
//                     contractAddress: config.BUSDAddress,
//                     abi: BusdABI,
//                     calls: [
//                         {
//                             reference: "getbalance",
//                             methodName: "balanceOf",
//                             methodParameters: [address],
//                         },

//                     ],
//                 },
//             ];

//             const resultTwo = await multicall.call(contractCallContextTwo)
//             var balance = await getFormatMulticall(resultTwo, "getbalance", 0)
//             var BusdBal = await bignumber(balance[0]);
//             BusdBal = parseFloat(BusdBal) / 10 ** 18;

//             return {
//                 teamCount: myteamCount,
//                 totalearnedToken: parseFloat(earnedToken) / 1e18,
//                 status: true,
//                 isexist: isexist,
//                 UserProfileId: userid,
//                 AllUsers: totaluser,
//                 BusdBal: BusdBal,
//                 totalearnAmt: totalearnedAmt,
//                 dirReferralcount: myreferral && myreferral.length,
//                 upgradeAmount: upgradeAmount,
//                 upgradePending: upgradePending,
//                 yourearnedAmt: yourearnedAmt,
//                 RoyaltyIncome: RoyaltyIncome,
//                 locktokenAmt: locktokenAmt,
//                 currentLevel: currlevel,
//                 genertionIncome: genertionIncome,
//                 upgradematrixIncome: upgradematrixIncome,
//                 globalroyality1income: globalroyality1income,
//                 globalroyality2income: globalroyality2income,
//                 l2_missedearnings: l2_missedearnings,
//                 l3_missedearnings: l3_missedearnings,
//                 l4_missedearnings: l4_missedearnings,
//                 l5_missedearnings: l5_missedearnings,
//                 l6_missedearnings: l6_missedearnings,
//                 l7_missedearnings: l7_missedearnings,
//                 l7_missedearnings: l7_missedearnings,
//                 l8_missedearnings: l8_missedearnings,
//                 l9_missedearnings: l9_missedearnings,
//                 l10_missedearnings: l10_missedearnings,
//                 directreferralIncome: directreferralIncome
//             }
//         }
//         else {
//             return {
//                 teamCount: 0,
//                 totalearnedToken: 0,
//                 status: false,
//                 isexist: '',
//                 message: 'Error on server',
//                 UserProfileId: '',
//                 AllUsers: '',
//                 BusdBal: 0,
//                 totalearnAmt: 0,
//                 dirReferralcount: 0,
//                 upgradeAmount: 0,
//                 upgradePending: 0,
//                 yourearnedAmt: 0,
//                 RoyaltyIncome: 0,
//                 locktokenAmt: 0,
//                 currentLevel: 0,
//                 genertionIncome: 0,
//                 upgradematrixIncome: 0,
//                 globalroyality1income: 0,
//                 globalroyality2income: 0,
//                 l2_missedearnings: 0,
//                 l3_missedearnings: 0,
//                 l4_missedearnings: 0,
//                 l5_missedearnings: 0,
//                 l6_missedearnings: 0,
//                 l7_missedearnings: 0,
//                 l8_missedearnings: 0,
//                 l9_missedearnings: 0,
//                 l10_missedearnings: 0,
//                 directreferralIncome: 0
//             }
//         }
//     }
//     catch (err) {
//         console.log('errasasasasas: ', err);
//         return {
//             teamCount: 0,
//             totalearnedToken: 0,
//             status: false,
//             isexist: '',
//             message: 'Error on server',
//             UserProfileId: '',
//             AllUsers: '',
//             BusdBal: 0,
//             totalearnAmt: 0,
//             dirReferralcount: 0,
//             upgradeAmount: 0,
//             upgradePending: 0,
//             yourearnedAmt: 0,
//             RoyaltyIncome: 0,
//             locktokenAmt: 0,
//             currentLevel: 0,
//             genertionIncome: 0,
//             upgradematrixIncome: 0,
//             globalroyality1income: 0,
//             globalroyality2income: 0,
//             l2_missedearnings: 0,
//             l3_missedearnings: 0,
//             l4_missedearnings: 0,
//             l5_missedearnings: 0,
//             l6_missedearnings: 0,
//             l7_missedearnings: 0,
//             l8_missedearnings: 0,
//             l9_missedearnings: 0,
//             l10_missedearnings: 0,
//             directreferralIncome: 0
//         }
//     }
// }
// export function getFormatMulticall(results, name, pos) {
//     try {
//         var index = results && results.results && results.results.details &&
//             results.results.details.callsReturnContext &&
//             results.results.details.callsReturnContext.findIndex(val => val.reference == name);
//         var returnVal = results.results.details.callsReturnContext[index] &&
//             results.results.details.callsReturnContext[index].returnValues ?
//             results.results.details.callsReturnContext[index].returnValues : "";
//         return returnVal;
//     } catch (err) {
//         console.log(err, "==+++++++")
//         return "";
//     }
// }
// export const UpgradeAction = async (upgradeAmount, isexist, currentLevel) => {
//     try {
//         var get = await connection()
//         if (get && get.web3 && get.address) {
//             var address = get.address;
//             var web3 = new Web3(get.web3);
//             if (parseFloat(upgradeAmount) <= 0) {
//                 return {
//                     status: false,
//                     message: 'Upgrade amount is too low'
//                 }
//             }
//             if (isexist == false) {
//                 return {
//                     status: false,
//                     message: 'Join first to proceed further'
//                 }
//             }
//             else {
//                 var Contract = new web3.eth.Contract(DefiQABI, config.ambasscontract);
//                 var data = await Contract.methods.upgradeNextLevel().send({ from: address });
//                 if (data && data.status == true && (!isEmpty(data.transactionHash))) {

//                     var userss = await Contract.methods.users(address).call();
//                     let _level = userss && userss.currentLevel;
//                     _level = parseFloat(_level)
//                     let upgrade_data = {
//                         address: address,
//                         level: _level
//                     }
//                     await levelupgrade(upgrade_data);
//                     return {
//                         status: true,
//                         message: "Upgraded successfully"
//                     }
//                 }
//                 else {
//                     return {
//                         status: false,
//                         message: 'Transaction rejected'
//                     }
//                 }
//             }
//         }
//     }
//     catch (err) {
//         console.log(err, 'erreeeeerrrrr')
//         return {
//             status: false,
//             message: 'Transaction rejected'
//         }
//     }
// }
// export const DepositAction = async (depositamt, isexist) => {
//     try {
//         var get = await connection();
//         var address = get.address;
//         var web3 = new Web3(get.web3);

//         if (depositamt == null || depositamt == undefined || depositamt == "") {
//             return {
//                 status: false,
//                 message: 'Enter Valid Amount'
//             }
//         } else if (parseFloat(depositamt) <= 0) {
//             return {
//                 status: false,
//                 message: 'Deposit amount is too low'
//             }
//         } else if (parseFloat(depositamt) > 160) {
//             return {
//                 status: false,
//                 message: 'Maximum Deposit Amount : 160 USDT'
//             }
//         } else if (isexist == false) {
//             return {
//                 status: false,
//                 message: 'Join first to proceed further'
//             }
//         }
//         else {
//             var Contract = new web3.eth.Contract(BusdABI, config.BUSDAddress);
//             var approveAmt = depositamt * (10 ** 18);
//             approveAmt = await convert(approveAmt);
//             var result1 = await Contract.methods.approve(
//                 config.ambasscontract,
//                 approveAmt.toString()
//             ).send({ from: address });
//             if (result1 && result1.transactionHash && result1.status == true) {
//                 var defiContract = new web3.eth.Contract(DefiQABI, config.ambasscontract);
//                 var data = await defiContract.methods.depositToUpgrade(approveAmt.toString()).send({ from: address });
//                 if (data && data.status == true && (!isEmpty(data.transactionHash))) {
//                     return {
//                         status: true,
//                         message: "Deposited successfully"
//                     }
//                 }
//                 else {
//                     return {
//                         status: false,
//                         message: 'Transaction rejected'
//                     }
//                 }
//             }
//         }
//     }
//     catch (err) {
//         console.log(err, 'eeeeeeeeeeeerrrr')
//         return {
//             status: false,
//             message: 'Transaction rejected'
//         }
//     }
// }
// export const WithdrawAction = async (withdrawAmt, upgradePending, isexist, curr_lev) => {
//     try {
//         var get = await connection();
//         var address = get.address;
//         var web3 = new Web3(get.web3);
//         if (parseFloat(withdrawAmt) <= 0) {
//             return {
//                 status: false,
//                 message: 'Withdraw amount is too low'
//             }
//         }
//         if (isexist == false) {
//             return {
//                 status: false,
//                 message: 'Join first to proceed further'
//             }
//         }
//         else {
//             let upgradependingAmt = parseFloat(upgradePending);
//             if (upgradependingAmt > 0 && parseInt(curr_lev) < 10) {
//                 return {
//                     status: false,
//                     message: 'you can withdraw,only after upgrade'
//                 }
//             }
//             else {
//                 var Contract = new web3.eth.Contract(DefiQABI, config.ambasscontract);
//                 var data = await Contract.methods.claimRewards().send({ from: address });
//                 if (data && data.status == true && (!isEmpty(data.transactionHash))) {
//                     return {
//                         status: true,
//                         message: "Claimed successfully"
//                     }
//                 }
//                 else {
//                     return {
//                         status: false,
//                         message: 'Transaction rejected'
//                     }
//                 }
//             }
//         }
//     }
//     catch (err) {
//         console.log(err, 'eeeeeeeeeeeerrrr')
//         return {
//             status: false,
//             message: 'Transaction rejected'
//         }
//     }
// }