import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { plandata, getuserplandata, getadmindetails } from "../Action/apiAction"
import DefiQABI from '../ABI/DefiQABI.json'
import { convert } from "../helper/convert"
import config from '../config/config'
import { connection } from "../helper/connection"
import Web3 from "web3";
import { useSelector } from "react-redux";
import BusdABI from '../ABI/BusdABI.json'
import { approveAction, getAllowanceDetails, JoinNowAction } from "../Action/ContractActions.js";
import ReactLoading from 'react-loading'
import { toastAlert } from "../helper/toastAlert";
import isEmpty from "is-empty";

export default function PlanInfo() {

    const [Plandata, setPlandata] = useState([])
    const [userplandata, setuserplandata] = useState([])
    const [allplandata, setallplandata] = useState([])
    const [userPlanData, setuserPlanData] = useState([])
    const [Error, setError] = useState("")
    const [load, setload] = useState(false)
    const [referalAddress, setReferalAddress] = useState("")
    const [joinplanPid, setjoinplanPid] = useState(0)
    const [joinplanAmount, setjoinplanAmount] = useState()
    const [adminreferrealdata, setadminreferrealdata] = useState([])
    const [addfrom, setaddfrom] = useState(false)
    const [planName, setplanName] = useState()


    const [getbuttondiable, setbuttondiable] = useState(false);

    const location = useLocation();

    console.log(userPlanData, "location");


    const Connection = useSelector((state) => state.walletConnection)


    useEffect(() => {
        getuser(Connection.address)

    }, [Connection])



    const getuser = async (add) => {
        const result = await plandata()
        let reqdata = {
            address: add
        }
        console.log(reqdata);
        const userdata = await getuserplandata(reqdata)
        console.log(userdata.data.data, result.data.data, "result");
        setuserPlanData(userdata.data.data);
        setallplandata(result.data.data)
        const userplan = await samebetweenarray(userdata.data.data, result.data.data);
        const balplan = await differencebetweenarrayvalue(userdata.data.data, result.data.data);
        setPlandata(balplan)
        setuserplandata(userplan)
    }

    const samebetweenarray = async (arr1, arr2) => {
        return arr2.filter(item1 => {
            return arr1.some(item2 => {
                if (item2.PlanID === item1.PlanID) {
                    return item2
                }
            });
        });
    }
    const differencebetweenarrayvalue = async (arr1, arr2) => {
        return arr2.filter(item1 => {
            return !arr1.some(item2 => item2.PlanID === item1.PlanID);
        })
    }


    const handlechange = (e) => {
        const { value } = e.target;
        setError()
        setReferalAddress(value)
    }


    const handleclose = (e) => {
        // alert(e.target.value)
        setError("")
        setaddfrom(false)
        setReferalAddress("")
    }



    const onclickrejoindata = (amt, pid, name) => {
        setjoinplanAmount(amt)
        setjoinplanPid(pid)
        setplanName(name)

        console.log(amt, pid, 'amt, pid');
        getadmindata(pid)

    }


    const getadmindata = async (pid) => {
        try {
            let reqdata = {
                address: config.AdminAddress,
                planid: pid
            }

            const result = await getadmindetails(reqdata)
            console.log(result, "result");
            setadminreferrealdata(result.data.data)
        } catch (err) {
            console.log(err);
        }

    }



    const joinPlan = async (type) => {
        try {

            if (type == "with") {
                if (referalAddress == "") {
                    setError("Continue without referral Id")
                    return false
                }
            }


            let refferal = referalAddress == "" ? `AMBPSILVER${adminreferrealdata[0].userid}` : referalAddress

            setload(true)
            console.log(joinplanPid, joinplanAmount, Connection.address, referalAddress, "SaranRaj")
            const { status, message } = await JoinNowAction(joinplanPid, joinplanAmount, Connection.address, refferal)


            setload(false)
            toastAlert("error", message, "error");
            if (message == "Invalid Referral id") {
                setReferalAddress("")
                setError("Invalid Referral id")
                return false
            }
            // }
            console.log(status, message);



        } catch (err) {
            toastAlert("error", "Transaction Rejected", "error");
            console.log(err, "errerrerrerrsaran")

        }
    }

    const rejoinplan = async (pid, amount) => {
        try {
            // setload(true)
            setbuttondiable(true)
            var get = await connection();
            var web3 = new Web3(get.web3);
            var approveamt = await convert(1000 * 1e18);
            var BuyAmount = await convert(amount * 1e18);
            var BusdBlanace = new web3.eth.Contract(BusdABI, config.BUSDAddress)


            const balace = await BusdBlanace.methods.balanceOf(Connection.address).call();

            let balac = balace / 1e18
            if (balac < amount) {
                toastAlert("error", "User usdt balance is Low", "error");
                setbuttondiable(false)
                return false
            }
            setload(true)

            await BusdBlanace.methods.approve(config.ambasscontract, approveamt.toString()).send({ gasLimit: 250000, from: Connection.address }).then(async (res) => {
                var mlmcontract = new web3.eth.Contract(DefiQABI, config.ambasscontract)
                await mlmcontract.methods.rejoin(pid, BuyAmount.toString()).send({ from: Connection.address }).then((res) => {
                    toastAlert("success", "Rejoin successfully", "success");
                    setload(false)
                    setbuttondiable(false)
                    setTimeout(() => {
                        window.location.reload()
                    }, 1500);
                })
            })

        } catch (err) {
            setload(false)
            setbuttondiable(false)
            toastAlert("error", "Transaction Rejected", "error");
            console.log(err);
        }
    }



    return (
        <>
            {
                load && <ReactLoading type={"spin"} color={"#000000"} className="loading" />
            }
            <ul
                className="nav nav-pills package_tab package_tab_fullwidth package_dashboard"
                id="pills-tab"
                role="tablist"
            >
                {allplandata.map((item) => (
                    <li className="nav-item123" role="presentation">
                        {userplandata.findIndex(planUser => planUser.PlanID === item.PlanID) != -1 &&
                            userPlanData.findIndex(planUser => planUser.userStaus === 0) == -1 ?
                            <Link className="nav-link" to={`/dashboard-${item.PoolColor.toLowerCase()}/${item.PlanID}`}>
                                <div className={`${(item.PoolColor.toLowerCase())}_tabbtn tabbtn`}>
                                    <span>{item.poolName.toUpperCase() + " - " + item.poolPrize + " " + "Usdt"}</span>

                                    {/* {location.pathname == `/dashboard-${item.poolName}` ? */}
                                    {userplandata.findIndex(planUser => planUser.PlanID === item.PlanID) != -1 ?

                                        // userPlanData.findIndex(planUser => planUser.uplineAmount === 0) != -1 &&

                                        userPlanData.findIndex(planUser => planUser.userStaus === 0 && planUser.PlanID === item.PlanID) != -1 ?
                                            <button className="select_link active" disabled={getbuttondiable} onClick={() => rejoinplan(item.PlanID, item.poolPrize)}  style={{width: "120px"}}>ReJoin</button>
                                            :
                                            <small className="select_link">Current Dashboard</small>
                                        :
                                        <button className="select_link"
                                            data-bs-toggle="modal"
                                            data-bs-target="#joinnow_modal1"
                                            onClick={() => onclickrejoindata(item.poolPrize, item.PlanID, item.poolName)}
                                        >Join Now</button>
                                    }
                                </div>
                            </Link>
                            :
                            <Link className="nav-link" to={`#`}>
                                <div className={`${(item.PoolColor.toLowerCase())}_tabbtn tabbtn`}>
                                    <span>{item.poolName.toUpperCase() + " - " + item.poolPrize + " " + "Usdt"}</span>

                                    {/* {location.pathname == `/dashboard-${item.poolName}` ? */}



                                    {userplandata.findIndex(planUser => planUser.PlanID === item.PlanID) != -1 ?

                                        // userPlanData.findIndex(planUser => planUser.uplineAmount === 0) != -1 &&


                                        userPlanData.findIndex(planUser => planUser.userStaus === 0 && planUser.PlanID === item.PlanID) != -1 ?
                                            <button className="select_link active" disabled={getbuttondiable} onClick={() => rejoinplan(item.PlanID, item.poolPrize)} style={{width: "120px"}}>ReJoin</button>
                                            :
                                            <small className="select_link">Current Dashboard</small>
                                        :
                                        <button className="select_link"
                                            data-bs-toggle="modal"
                                            data-bs-target="#joinnow_modal1"
                                            onClick={() => onclickrejoindata(item.poolPrize, item.PlanID, item.poolName)}
                                        >Join Now</button>
                                    }
                                </div>
                            </Link>
                        }

                    </li>
                ))}
            </ul>

            {/* <button
                              className="std_join_btn"
                              data-bs-toggle="modal"
                              data-bs-target="#joinnow_modal"
                              onClick={() => onclickjoindata(item.poolPrize, item.PlanID)}
                            >
                              Join
                            </button> */}
            {/* Join Now Modal */}
            <div
                className="modal fade primary_modal"
                id="joinnow_modal1"
                tabIndex={-1}
                aria-labelledby="joinnow_modalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="joinnow_ModalLabel">
                                Join Now
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={handleclose}

                            ></button>
                        </div>
                        <div className="modal-body">
                            {addfrom &&
                                <div className="form-group mb-3">
                                    <input
                                        placeholder="Enter Referral ID"
                                        className="form-control primary_inp"
                                        onChange={handlechange}
                                        value={referalAddress}
                                    />
                                    <small style={{ fontSize: "12px", float: "right", display: "block !important", textAlign: "right !important" }}>Example:AMBP{planName.toUpperCase()}12345</small>

                                    {!isEmpty(Error) ? <span style={{ color: "red" }} className="errorText">{Error}</span> : ""}
                                </div>}
                            <div className="text-center" style={{ display: "flex" }}>
                                {!addfrom &&
                                    <button class="primary_btn" style={{ marginRight: "20px" }} onClick={() => setaddfrom(true)}>Add referral</button>}

                                {addfrom && isEmpty(Error) && <button class="primary_btn" onClick={() => joinPlan("with")}>submit</button>}

                                <br></br>
                                {!addfrom &&
                                    <button class="primary_btn" onClick={() => joinPlan("without")}>Continue without referral</button>
                                }
                                {!isEmpty(Error) &&
                                    <button class="primary_btn" style={{ marginRight: "20px" }} onClick={() => setError("")}>Add referral</button>
                                }
                                {!isEmpty(Error) &&
                                    <button class="primary_btn" style={{ marginRight: "20px" }} onClick={() => joinPlan("without")}>Continue without referral</button>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>

    )


}

