let NODE_ENV = 'demo';
//let NODE_ENV = 'demo12';

let Front_URL = "";
let Server_URL = "";

let baseUrl = "";
let BUSDAddress = "";
let contractAddress = "";

let BNBSYMBOL = "";
let toFixedSwap = "";
let toFixedBal = "";
let NetworkId = "";
let toFixed = "";
let networkName = "";
let toFixedvalue = "";
let txUrlAddress = "";
let txurl = "";
let tokenSymbol = "";
let AdminAddress = "";
let DefiQTokenAddress = "";
let projectId = "";
let rpcurl = "";

if (NODE_ENV == "production") {

    // Front_URL = 'https://ambprogram.com/';
    // Server_URL = 'https://productionapi.ambprogram.com/';
    // baseUrl = 'https://productionapi.ambprogram.com';
    // AdminAddress = "0x466F026ab9eeA0195F4Ada62853aD21BB336CcA4";
    // // BUSDAddress = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
    // BUSDAddress = "0x55d398326f99059fF775485246999027B3197955";
    // contractAddress = "0x698325b05a70bF88EF9855d2a06CC14473DCC4Fc";
    // // DefiQTokenAddress = "0x866E7B8F76CF2435Fde81DEA9FB4693Ba002DF8F";

    // rpcurl = "https://bsc-dataseed.binance.org/";
    // txUrlAddress = "https://bscscan.com/address/";
    // txurl = "https://bscscan.com/tx/";
    // BNBSYMBOL = 'BNB';
    // toFixedSwap = 18;
    // toFixedvalue = 8;
    // NetworkId = 56;
    // toFixed = 8;
    // toFixedBal = 18;
    // networkName = "Binance Chain";
    // tokenSymbol = "DefiQ"
    // projectId = "cb89ebb21cdccb2e1b591e189e27706a";//wallet connect

}
else if (NODE_ENV == "demo") {
    Front_URL = 'https://ambassadormlm-frontend.pages.dev/';
    Server_URL = 'https://ambassadorapi.wearedev.team/';
    baseUrl = 'https://ambassadorapi.wearedev.team'
    AdminAddress = "0x80Aa595c83430288E3A75F4D03bE15b72385420F";
    BUSDAddress = "0xBB6b2110D62952102606E38aB0B3faf174427066";
    contractAddress = "0x9daDBd2098efe71392c35e30aAf93Fe90BcA1CA3"
    rpcurl = "https://bsc-testnet.public.blastapi.io";
    // DefiQTokenAddress = "0x006C4d9Ae59EDd1c7Dcc8a41d86B8a46881f1421";
    txUrlAddress = "https://testnet.bscscan.com/address/";
    txurl = "https://testnet.bscscan.com/tx/";
    BNBSYMBOL = 'BNB';
    toFixedSwap = 18;
    toFixedvalue = 8;
    NetworkId = 97;
    toFixed = 8;
    toFixedBal = 18;
    networkName = "Binance Chain";
    // tokenSymbol = "DefiQ"
    projectId = "cb89ebb21cdccb2e1b591e189e27706a";//wallet connect
}

else {
    Front_URL = 'http://localhost:3001/';
    Server_URL = 'http://localhost:3100/';
    baseUrl = 'http://localhost:4001'
    AdminAddress = "0x80Aa595c83430288E3A75F4D03bE15b72385420F";
    BUSDAddress = "0xBB6b2110D62952102606E38aB0B3faf174427066";
    contractAddress = "0x92227e53596Bc5E462F8C6f9A05a8C975277273c"
    // DefiQTokenAddress = "0x006C4d9Ae59EDd1c7Dcc8a41d86B8a46881f1421";
    rpcurl = "https://bsc-testnet.publicnode.com";
    txUrlAddress = "https://testnet.bscscan.com/address/";
    txurl = "https://testnet.bscscan.com/tx/";
    BNBSYMBOL = 'BNB';
    toFixedSwap = 18;
    toFixedvalue = 8;
    NetworkId = 97;
    toFixed = 8;
    toFixedBal = 18;
    networkName = "Binance Chain";
    tokenSymbol = "DefiQ"
    projectId = "cb89ebb21cdccb2e1b591e189e27706a";//wallet connect
}

let key = {
    Front_URL: Front_URL,
    Server_URL: Server_URL,
    baseUrl: baseUrl,
    AdminAddress: AdminAddress,
    BUSDAddress: BUSDAddress,
    ambasscontract: contractAddress,
    BNBSYMBOL: BNBSYMBOL,
    toFixedSwap: toFixedSwap,
    toFixedBal: toFixedBal,
    toFixedvalue: toFixedvalue,
    NetworkId: NetworkId,
    toFixed: toFixed,
    networkName: networkName,
    txUrlAddress: txUrlAddress,
    txurl: txurl,
    tokenSymbol: tokenSymbol,
    DefiQTokenAddress: DefiQTokenAddress,
    projectId: projectId,
    rpcurl: rpcurl
};

export default key;
